import { ActionTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import CookiesState from '../types/CookiesState';
import { COOKIES_SET_COOKIE_CONSENT } from './mutation-types';

const actions: ActionTree<CookiesState, RootState> = {
  async setCookieConsent ({ commit }, cookieConsent: object) {
    commit(COOKIES_SET_COOKIE_CONSENT, cookieConsent);
  }
};

export default actions;
