import { equipmentGuideStore } from './store';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus/index';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { isServer } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { EquipmentGuideService } from '@vue-storefront/core/data-resolver';

export const EquipmentGuideModule: StorefrontModule = function ({ store }) {
  StorageManager.init('equipmentGuide');
  if (!isServer) {
    EventBus.$on('application-after-init', async () => {
      const equipmentGuide = await EquipmentGuideService.getEquipmentGuideData();
      store.dispatch('equipmentGuide/setEquipmentGuide', equipmentGuide);
    });
  }
  store.registerModule('equipmentGuide', equipmentGuideStore);
};
