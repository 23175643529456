import config from 'config';
import { DataResolver } from './types/DataResolver';
import { EquipmentGuideNode } from 'core/modules/equipmentGuide/types/EquipmentGuideNode';
import { Logger } from '@vue-storefront/core/lib/logger';

const getEquipmentGuideData = async (): Promise<EquipmentGuideNode> => {
  const url = config.configurator.configurator_data_endpoint;
  const authorizationHeader = config.api.apiAuthorizationToken
    ? { 'Authorization': config.api.apiAuthorizationToken } : {};
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        ...authorizationHeader
      }
    });
    const equipmentGuide = await response.json();
    if (equipmentGuide?.code === 200) {
      return equipmentGuide.result;
    } else {
      throw new Error(`Error during equipment guide fetch. ${equipmentGuide?.code ? `status code: ${equipmentGuide.code}` : ''}`);
    }
  } catch (err) {
    Logger.error(err, 'equipmentGuideService');
    throw new Error(err);
  }
};
export const EquipmentGuideService: DataResolver.EquipmentGuideService = {
  getEquipmentGuideData: getEquipmentGuideData
};
