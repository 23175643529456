import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { PartnerService } from '@vue-storefront/core/data-resolver';
import Vue from 'vue'

export const PartnersModule: StorefrontModule = async function () {
  Vue.prototype.$bus.$on('application-after-init', async app => {
    let partners = [];
    try {
      partners = await PartnerService.getPartners();
    } catch (ex) {
      console.error(ex);
    }
    await app.$store.dispatch('partner/setPartners', partners);
  });
};
