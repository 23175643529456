import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import EquipmentGuideState from '../types/EquipmentGuideState'

const mutations: MutationTree<EquipmentGuideState> = {
  [types.EQUIPMENT_GUIDE_SET_EQUIPMENT_GUIDE] (state, equipmentGuide) {
    state.equipmentGuide = equipmentGuide
  }
}

export default mutations
