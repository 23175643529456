import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import SearchbarCategories from './types/searchbarCategories'

export const module: Module<SearchbarCategories, any> = {
  namespaced: true,
  state: {
    categories: [
      {type: 'empty'}
    ]
  },
  getters,
  actions,
  mutations
}
