import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import selectedOptions from './types/selectedOptions'

const getters: GetterTree<selectedOptions, RootState> = {
  getSelectedOptions (state) {
    return state.options
  }
}

export default getters
