import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import searchbarCategories from './types/searchbarCategories'

const getters: GetterTree<searchbarCategories, RootState> = {
  getCategories (state) {
    return state.categories
  }
}

export default getters
