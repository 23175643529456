<template>
  <div id="app" :data-theme="getTheme()">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from 'config'

const DefaultLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default')
const EmptyLayout = () => import(/* webpackChunkName: "vsf-layout-empty" */ './layouts/Empty')
const MinimalLayout = () => import(/* webpackChunkName: "vsf-layout-minimal" */ './layouts/Minimal')
// Used for threshold to re-direct the user to HP
const thresholdInMinutes = (mins) => {
  return 1000 * 60 * mins
}

export default {
  data () {
    return {
      data_theme: '',
      lastVisible: null
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay,
      appScrollLock: state => state.ui.appScrollLock
    }),
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    },
  },
  methods: {
    getTheme() {
      return this.$ssrContext?.partnerData.cssTheme || config.partnerData.cssTheme;
    },
  },
  components: {
    DefaultLayout,
    EmptyLayout,
    MinimalLayout
  },
  watch: {
    appScrollLock: (locked) => {
      if (locked) {
        document.body.classList.add('body-fixed');
      } else {
        document.body.classList.remove('body-fixed');
      }
    }
  },
  mounted () {
    document.addEventListener('visibilitychange', () => {
      // If user is on, or comes back to the opened page
      if (!document.hidden) {
        // Re-directing to HP after specified number of minutes
        if (this.lastVisible !== null && (new Date().getTime() - this.lastVisible) > thresholdInMinutes(30)) {
          this.$router.push('/')
        }
      } else {
        this.lastVisible = new Date().getTime();
      }
    });
  }
}
</script>

<style lang="scss">
body.body-fixed {
  overflow: hidden;
}
</style>
