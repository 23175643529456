import { MutationTree } from 'vuex'
import ReservationsState from '../../types/ReservationsState'
import * as types from './mutation-types'

const mutations: MutationTree<ReservationsState> = {
  [types.SET_DATE_FROM] (state, dateFrom) {
    // console.log('mutation dateFrom', dateFrom);
    state.dateFrom = dateFrom
  },
  [types.SET_DATE_TO] (state, dateTo) {
    state.dateTo = dateTo
  }
}
export default mutations
