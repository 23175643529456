import * as types from './mutation-types';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import AttributeState from '../../types/AttributeState';
import RootState from '@vue-storefront/core/types/RootState';
import { ActionTree } from 'vuex';
import config from 'config';
import { Logger } from '@vue-storefront/core/lib/logger';
import { entityKeyName } from '@vue-storefront/core/lib/store/entities';
import { prefetchCachedAttributes } from '../../helpers/prefetchCachedAttributes';
import createAttributesListQuery from './../../helpers/createAttributesListQuery';
import reduceAttributesLists from './../../helpers/reduceAttributesLists';
import filterAttributes from '../../helpers/filterAttributes';
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery';
import i18n from '@vue-storefront/core/i18n';

const actions: ActionTree<AttributeState, RootState> = {
  async updateAttributes ({ commit, getters }, { attributes }) {
    const idsList = getters.getAttributeListById;
    const codesList = getters.getAttributeListByCode;

    for (const attr of attributes) {
      if (attr && !config.attributes.disablePersistentAttributesCache) {
        const attrCollection = StorageManager.get('attributes');

        try {
          await attrCollection.setItem(entityKeyName('attribute_code', attr.attribute_code.toLowerCase()), attr);
          await attrCollection.setItem(entityKeyName('attribute_id', attr.attribute_id.toString()), attr);
        } catch (e) {
          Logger.error(e, 'mutations')();
        }
      }
    }

    commit(types.ATTRIBUTE_UPD_ATTRIBUTES, reduceAttributesLists({ codesList, idsList, attributes }));
  },
  async loadCachedAttributes ({ dispatch }, { filterField, filterValues }) {
    if (!filterValues) {
      return;
    }

    const attributes = await prefetchCachedAttributes(filterField, filterValues);

    if (attributes) {
      await dispatch('updateAttributes', { attributes });
    }
  },
  updateBlacklist ({ commit, getters }, { filterValues, filterField, attributes }) {
    if (attributes && filterValues.length > 0) {
      const foundValues = attributes.map(attr => attr[filterField]);
      const toBlackList = filterValues.filter(ofv => !foundValues.includes(ofv) && !getters.getBlacklist.includes(ofv));
      commit(types.ATTRIBUTE_UPD_BLACKLIST, toBlackList);
    }
  },
  /**
   * Load attributes for the apps use without using config
   */
  async preloadAttributes ({ commit }) {
    let searchQuery = new SearchQuery();
    searchQuery = searchQuery.applyFilter({ key: 'is_user_defined', value: { 'in': [true] } });
    searchQuery = searchQuery.applyFilter({ key: 'is_visible', value: { 'in': [true] } });

    const resp = await quickSearchByQuery({
      entityType: 'attribute',
      query: searchQuery,
      includeFields: null,
      start: 0,
      size: 150
    });

    const preloaded = [];
    // add the original filters from config
    for (const codeString of config.products.defaultFilters) {
      preloaded.push(codeString);
    }
    // add the dynamically added filters from elastic
    for (const attr of resp.items) {
      preloaded.push(attr.attribute_code);
    }
    // this is a workaround for dynamically changeable filters
    commit(types.ATTRIBUTE_SET_PRELOADED, preloaded);
    // this is ONLY used to determine proper labels for FE later
    // adding price for translations only !
    const fullFilters = Array.from(resp.items); // copy
    [{
      attribute_code: 'price',
      default_frontend_label: 'Cena'
    }, {
      attribute_code: 'partner',
      default_frontend_label: i18n.t('Pickup point')
    }].forEach(attribute => fullFilters.push(attribute));

    commit(types.ATTRIBUTE_SET_FULL, fullFilters);
    return 'filters loaded';
  },
  /**
   * Load attributes with specific codes
   * @param {Object} context
   * @param {Array} attrCodes attribute codes to load
   */
  async list ({ getters, dispatch }, {
    filterValues = null,
    filterField = 'attribute_code',
    only_user_defined = false,
    only_visible = false,
    size = 150,
    start = 0,
    includeFields = config.entities.optimize ? config.entities.attribute.includeFields : null
  }) {
    const blacklist = getters.getBlacklist;
    const idsList = getters.getAttributeListById;
    const codesList = getters.getAttributeListByCode;
    const orgFilterValues = filterValues || [];

    await dispatch('loadCachedAttributes', { filterField, filterValues });

    filterValues = filterAttributes({ filterValues, filterField, blacklist, idsList, codesList });

    if (filterValues.length === 0) {
      Logger.info('Skipping attribute load - attributes already loaded', 'attr', { orgFilterValues, filterField })();
      return { items: Object.values(codesList) };
    }

    const query = createAttributesListQuery({
      filterValues,
      filterField,
      onlyDefinedByUser: only_user_defined,
      onlyVisible: only_visible
    });

    const resp = await quickSearchByQuery({ entityType: 'attribute', query, includeFields, start, size });
    const attributes = resp && orgFilterValues.length > 0 ? resp.items : null;

    dispatch('updateBlacklist', { filterValues, filterField, attributes });
    await dispatch('updateAttributes', { attributes });
    return resp;
  }
};

export default actions;
