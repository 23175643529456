import { setupMultistoreRoutes } from '@vue-storefront/core/lib/multistore';
import App from './App.vue';
import routes from './router';
import Vue from 'vue';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';
//
import VueProgressBar from 'vue-progressbar';
import { once } from '@vue-storefront/core/helpers';
import { RouterManager } from '@vue-storefront/core/lib/router-manager';
import { extendModule } from '@vue-storefront/core/lib/module';

import { module as cartModule } from './store/cart';
import { module as additionalServices } from './store/additionalServices';
import { module as searchbarCategories } from './store/searchbarCategories';
import { module as selectedOptions } from './store/selectedOptions';

import { claimsStore } from 'theme/store/claims';
import { homepageStore } from 'theme/store/homepage';
import { uiStore } from 'theme/store/ui';
import { promotedStore } from 'theme/store/promoted-offers';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar);
});

const themeEntry = App;
function initTheme (app, router, store, config) {
  if (typeof window !== 'undefined') {
    (async () => {
      const Sentry = await import('@sentry/browser');

      Sentry.init({
        Vue: Vue,
        tracingOptions: {
          trackComponents: true
        },
        ignoreErrors: [
          /Category query returned empty result/,
          /Error: ENOENT: no such file or directory, stat '\/var\/www\/dist\//,
          /Error during render/,
          /Converting circular structure/
        ],
        environment: config.app.env,
        dsn: config.sentry.dsn,
        integrations: [
          new Integrations.BrowserTracing(),
          new CaptureConsole({
            levels: ['error']
          })
        ],
        logLevel: 1,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
      });
    })()
  }

  store.registerModule('themeCart', cartModule);
  store.registerModule('additionalServices', additionalServices);
  store.registerModule('searchbarCategories', searchbarCategories);
  store.registerModule('selectedOptions', selectedOptions);
  // Register theme routes for the current store. In a single store setup this will add routes exactly as they are in the router definition file '[theme]/router/index.js'
  // In a multistore setup, it depends on the config setting 'appendStoreCode' for the current store
  // - true = the store code will be added to the front of all routes, e.g. name: 'de-checkout', path: '/de/checkout'
  // - false = the store code will not be added. In this case you need to define custom routes for each of your stores
  // You can also define custom routes to use a different component, for example for German storeView checkout
  // To do so, exclude the desired storeView from the config.storeViews.mapStoreUrlsFor, set appendStoreCode = false, and map all the urls by your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized }
  // The 4th parameter is the route priority - a higher number will ensure the theme routes override any module routes. The default is 0.
  setupMultistoreRoutes(config, router, routes);
  RouterManager.addRoutes(routes, router);

  StorageManager.init('claims');
  store.registerModule('claims', claimsStore);
  store.registerModule('homepage', homepageStore);
  store.registerModule('ui', uiStore);
  store.registerModule('promoted', promotedStore);
}

const uiExtend = {
  key: 'ui',
  store: { modules: [{ key: 'ui', module: uiStore }] }
};
extendModule(uiExtend);

export { themeEntry, initTheme };
