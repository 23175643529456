import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import HistoryState from '../types/HistoryState'

const mutations: MutationTree<HistoryState> = {
  [types.HISTORY_SET_ROUTES] (state, route:{from:string, to:string}) {
    state.route = route
    state.history.push(route)
  }
}

export default mutations
