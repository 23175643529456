import { ActionTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import HistoryState from '../types/HistoryState';
import { HISTORY_SET_ROUTES } from './mutation-types'

const actions: ActionTree<HistoryState, RootState> = {
  async setRoute ({ commit }, routes: { from, to }) {
    commit(HISTORY_SET_ROUTES, routes);
  }
};

export default actions;
