import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import SelectedOptions from './types/selectedOptions'

export const module: Module<SelectedOptions, any> = {
  namespaced: true,
  state: {
    options: []
  },
  getters,
  actions,
  mutations
}
