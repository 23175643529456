import { MutationTree } from 'vuex';
import * as types from './mutation-types';
import PartnerState from './PartnerState';
import { IPartner } from 'core/data-resolver/types/IPartner';

const mutations: MutationTree<PartnerState> = {
  [types.PARTNERS_SET_PARTNERS] (state, partners: IPartner[]) {
    state.partners = partners;
  }
};

export default mutations;
