import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import HistoryState from '../types/HistoryState';

export const historyStore: Module<HistoryState, any> = {
  namespaced: true,
  state: {
    route: { from: null, to: null },
    history: []
  },
  actions,
  mutations,
  getters
};
