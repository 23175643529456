import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc as any);
dayjs.extend(timezone as any)
// import globalConfig from 'config';

const TIMEZONE = 'Europe/Prague';

const parseTimezoneDateFrom = (date):string => {
  let result = date;
  // console.log("%c ptzd From: "+date, "color:aliceblue; font-size:12px");
  if (date) {
    // TODO not pretty times
    // result = timezone.tz(date, TIMEZONE).startOf('day').format();
    result = dayjs.tz(date, TIMEZONE).hour(6).minute(0).format();
  }
  // console.log("%c ptzd From: "+result, "color:lightblue; font-size:12px");
  return result;
}

const parseTimezoneDateTo = (date): string => {
  let result = date;
  // console.log("%c ptzd To: "+date, "color:aliceblue; font-size:12px");
  if (date) {
    // TODO
    // result = timezone.tz(date, TIMEZONE).endOf('day').format();
    result = dayjs.tz(date, TIMEZONE).hour(21).minute(0).format();
  }
  // console.log("%c ptzd To: "+result, "color:lightblue; font-size:12px");
  return result;
}

export { parseTimezoneDateTo, parseTimezoneDateFrom };
