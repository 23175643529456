// import { ModalType } from 'src/constants/ModalTypeToSlugMapping';
import { ServerConfig } from './config.interface';

const anyrentConfig: ServerConfig = {
  id: 'anyrent',
  code: '__default_channel__',
  displayPartnerPages: false,
  cssTheme: 'default',
  modalTypeToSlugMapping: {
    'gdpr': 'zasady-ochrany-osobnich-udaju',
    'biometric': 'predani-biometrickych-udaju',
    'terms': 'obchodni-podminky'
  },
  data: {
    homepage: {
      static_page_tiles: {
        how_it_works: {
          title: 'Jak to<br>funguje?',
          link: '/static/jak-to-funguje',
          active: true
        },
        delivery_methods: {
          title: 'Výdejní<br>místa',
          link: '/static/zpusoby-doruceni',
          active: true
        },
        contact: {
          title: 'Kontakty',
          link: '/static/kontakty',
          active: true
        }
      }
    },
    product: {
      product_detail_tiles: {
        delivery_methods: {
          title: 'Pickup points',
          link: '',
          active: true
        },
        contacts: {
          title: 'Contacts',
          link: '',
          active: true
        }
      }
    }
  },
  imageToPreload: ['https://anyrent-web-images.s3.eu-central-1.amazonaws.com/homepage-23-24/banner01.webp'],
  faviconList: [
    "<link rel='icon' type='image/png' href='/assets/faviconANYRENT-32.png' sizes='32x32'>",
    "<link rel='icon' type='image/png' href='/assets/faviconANYRENT-16.png' sizes='16x16'>"
  ]
};

export default anyrentConfig;
