/**
 * Helper method for getting attribute name - TODO: to be moved to external/shared helper
 *
 * @param {String} attributeCode
 * @param {String} optionId - value to get label for
 */
import toString from 'lodash-es/toString'

export function optionLabel (state, { attributeKey, searchBy = 'code', optionId }) {
  const attrCache = state.labels[attributeKey]

  if (attrCache) {
    const label = attrCache[optionId]

    if (label) {
      return label
    }
  }
  const attr = state['list_by_' + searchBy][attributeKey]
  if (attr) {
    let opt //  sometimes we might get invalid data from BE
    if (attr.options) {
      opt = attr.options.find((op) => { // TODO: cache it in memory
        if (toString(op.value) === toString(optionId)) {
          return op
        }
      }) // TODO: i18n support with  multi-website attribute names
    } else {
      console.error('invalid options on attributes, fix in admin ->', attr)
      opt = { label: '0', value: 0 } // dummy to prevent fail
    }

    if (opt) {
      if (!state.labels[attributeKey]) {
        state.labels[attributeKey] = {}
      }
      state.labels[attributeKey][optionId] = opt.label
      return opt ? opt.label : optionId
    } else {
      return optionId
    }
  } else {
    return optionId
  }
}
