import { Module } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import RootState from '@vue-storefront/core/types/RootState';
import PartnerState from './PartnerState';

export const partnerModule: Module<PartnerState, RootState> = {
  namespaced: true,
  state: {
    partners: []
  },
  getters,
  actions,
  mutations
};
