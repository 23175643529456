import * as types from './mutation-types';
// import { Logger } from '@vue-storefront/core/lib/logger' // for debug

const actions = {
  // set and store selected options on category page.
  setOption ({state, commit}, filters) {
    const filterArr = []
    // keeping just the first ele of array as we cant have more selected variants of
    // the same filter. (u can have one price and one size to be selected)
    const values = Object.values(filters)
    if (values.length > 0) {
      for (const val of values) {
        if (Array.isArray(val)) {
          // omitting the other variants of the filter
          filterArr.push(val[0])
        }
      }
    }
    // add
    commit(types.SET, filterArr)
  }
};

export default actions;
