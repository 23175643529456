import axios from 'axios';
import config from 'config';

const normalizeIconInBrowser = (icon) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(icon, 'text/html');
  const node: any = doc.body.firstChild;

  node.removeAttribute('class');
  node.removeAttribute('width');
  node.removeAttribute('height');
  node.removeAttribute('stroke');

  node.setAttribute('stroke', 'white');

  return node.outerHTML;
}

const normalizeIconsOnServer = async (iconList) => {
  // lazy import - do not include cheerio in client bundle!
  const { default: cheerio } = await import(/* webpackChunkName: "cheerio" */ 'cheerio');

  return iconList.map((icon) => {
    const $ = cheerio.load(icon, null, false);

    $('svg').removeAttr('class');
    $('svg').removeAttr('width');
    $('svg').removeAttr('height');
    $('svg').removeAttr('stroke');

    $('svg').attr('stroke', 'white');

    return $.html();
  });
}

export const uiStore = {
  namespaced: true,
  state: {
    bannerIconList: [],
    sidebar: false,
    microcart: false,
    wishlist: false,
    searchpanel: false,
    configurator: false,
    newsletterPopup: false,
    overlay: false,
    loader: false,
    authElem: 'login',
    checkoutMode: false,
    openMyAccount: false,
    appScrollLock: false,
    chatBot: false,
    submenu: {
      depth: false,
      path: []
    }
  },
  mutations: {
    setBannerIconList (state, payload) {
      state.bannerIconList = payload;
    },
    setCheckoutMode (state, action) {
      state.checkoutMode = action === true
    },
    setAppScrollLock (state, action) {
      state.appScrollLock = action === true
    },
    setMicrocart (state, action) {
      state.microcart = action === true
      state.overlay = action === true
    },
    setSidebar (state, action) {
      state.sidebar = action === true
      state.overlay = action === true
    },
    setConfigurator (state, action) {
      state.configurator = action === true
    },
    setChatBot (state, action) {
      state.chatBot = action === true
    },
    setSubmenu (state, { id, depth }) {
      if (id) {
        state.submenu.path.push(id)
      } else if (state.submenu.path.length) {
        setTimeout(() => {
          state.submenu.path.pop()
        }, 300)
      }
      state.submenu.depth = state.submenu.depth > 0 && depth
    },
    setSearchpanel (state, action) {
      state.searchpanel = action === true
      state.overlay = action === true
    },
    setWishlist (state, action) {
      state.wishlist = action === true
      state.overlay = action === true
    },
    setOverlay (state, action) {
      state.overlay = action === true
    },
    setLoader (state, action) {
      state.loader = action === true
    },
    setAuthElem (state, action) {
      state.authElem = action
    }
  },
  actions: {
    async loadBannerIconList ({ commit }, partnerTiles) {
      if (typeof window === 'undefined') {
        // fetch is undefined. I don't know why
        // const svgList = await Promise.all(partnerTiles.map(({ iconUrl }) => fetch(iconUrl)));
        // const iconList = await Promise.all(svgList.map((svg: any) => svg.text()))
        const iconList = await Promise.all(partnerTiles.map(async ({ iconUrl }) => {
          const response = await axios.get(iconUrl);
          return response.data;
        }));

        const normalizedIconList = await normalizeIconsOnServer(iconList);
        commit('setBannerIconList', normalizedIconList);
      } else {
        const svgList = await Promise.all(partnerTiles.map(({ iconUrl }) => {
          return fetch(config.api.url + '/fetchSvg?url=' + iconUrl)
        }));
        const iconList = await Promise.all(svgList.map((svg: any) => svg.text()))

        const normalizedIconList = iconList.map((icon) => normalizeIconInBrowser(icon));
        commit('setBannerIconList', normalizedIconList);
      }
    },
    toggleMicrocart ({ commit, state }) {
      commit('setMicrocart', !state.microcart)
    },
    toggleWishlist ({ commit, state }) {
      commit('setWishlist', !state.wishlist)
    },
    toggleConfigurator ({ commit, state }) {
      commit('setConfigurator', !state.configurator)
    },
    setAppScrollLock ({ commit }, lockScrollValue) {
      commit('setAppScrollLock', lockScrollValue);
    },
    setChatBot ({ commit }, state) {
      commit('setChatBot', state);
    }
  }
}
