import Vue from 'vue';
import { isServer } from '@vue-storefront/core/helpers';
import config from 'config';

function partnerColor () {
  return config.partnerData.displayPartnerPages
    ? config.partnerData.id === 'sbcr'
      ? '#162e55'
      : '#ea6722'
    : '#fc3711';
}

function initializeCookieConsentColors() {
  // FIXME why the F is this not used from variables, it is unusable for more then 1 additional partner
  const colorMap = {
    '--color-partner': partnerColor(),
    '--color-anyrent': '#fc3711',
    '--color-anyrent-hover': partnerColor(),
    '--color-matterhorn': '#4f4f4f',
    '--color-matterhorn-hover': '#454545',
    '--color-white-smoke': '#f0eeec',
    '--color-white-smoke-hover': '#dcdad8',
    '--color-white': 'white',
    '--cc-bg': 'var(--color-white)',
    '--cc-text': 'var(--color-matterhorn)',
    '--cc-btn-primary-bg': 'var(--color-partner)',
    '--cc-btn-primary-text': 'var(--color-white)',
    '--cc-btn-primary-hover-bg': partnerColor(),
    '--cc-btn-secondary-bg': 'var(--color-white-smoke)',
    '--cc-btn-secondary-text': 'var(--cc-text)',
    '--cc-btn-secondary-hover-bg': 'var(--color-white-smoke-hover)',
    '--cc-toggle-bg-off': '#818181',
    '--cc-toggle-bg-on': 'var(--color-anyrent)',
    '--cc-toggle-bg-readonly': 'rgba(129, 129, 129, 0.46)',
    '--cc-toggle-knob-bg': 'var(--color-white)',
    '--cc-toggle-knob-icon-color': '#ecf2fa',
    '--cc-block-text': 'var(--cc-text)',
    '--cc-cookie-category-block-bg': 'var(--color-white-smoke)',
    '--cc-cookie-category-block-bg-hover': 'var(--color-white-smoke-hover)',
    '--cc-section-border': 'var(--color-white-smoke)',
    '--cc-cookie-table-border': '#e9edf2',
    '--cc-overlay-bg': 'rgba(4, 6, 8, 0.85)',
    '--cc-webkit-scrollbar-bg': 'var(--color-white-smoke)',
    '--cc-webkit-scrollbar-bg-hover': 'var(--color-white-smoke-hover)',
  };

  Object.entries(colorMap).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
  });
}

export function afterApplicationInit() {
  Vue.prototype.$bus.$on('application-after-init', app => {
    app.$router.afterEach(to => {
      if (!isServer) {
        const cookieConsent = app.$store.getters['cookies/getCookieConsent'];
        if (!cookieConsent) {
          initializeCookieConsentColors();
          const cookieConsent = (window as any).initCookieConsent();
          app.$store.dispatch('cookies/setCookieConsent', cookieConsent);

          cookieConsent.run({
            current_lang: 'cs',
            autoclear_cookies: true,
            cookie_name: 'ar_cookie_consent',
            cookie_expiration: 365,
            page_scripts: true,
            force_consent: true,

            onFirstAction: () => {
              app.$gtm.trackEvent({
                event: 'cookieConsentUpdated',
                consents: {
                  analytics: (cookieConsent && cookieConsent.allowedCategory('analytics')) || false,
                  targeting: (cookieConsent && cookieConsent.allowedCategory('targeting')) || false,
                },
              });
            },
            onChange: () => {
              app.$gtm.trackEvent({
                event: 'cookieConsentUpdated',
                consents: {
                  analytics: (cookieConsent && cookieConsent.allowedCategory('analytics')) || false,
                  targeting: (cookieConsent && cookieConsent.allowedCategory('targeting')) || false,
                },
              });
            },

            gui_options: {
              consent_modal: {
                layout: 'box', // box,cloud,bar
                position: 'middle center', // bottom,middle,top + left,right,center
                transition: 'slide', // zoom,slide
              },
              settings_modal: {
                layout: 'box', // box,bar
                transition: 'slide', // zoom,slide
              },
            },
            languages: {
              cs: {
                consent_modal: {
                  title: `🍪 Tato webová stránka používá cookies!`,
                  description:
                    'K personalizaci obsahu a reklam, poskytování funkcí sociálních médií a analýze naší návštěvnosti využíváme soubory cookie. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro sociální média, inzerci a analýzy.',
                  primary_btn: {
                    text: 'Povolit vše',
                    role: 'accept_all',
                  },
                  secondary_btn: {
                    text: 'Manuální nastavení',
                    role: 'settings',
                  },
                },
                settings_modal: {
                  title: 'Nastavení cookies',
                  save_settings_btn: 'Uložit',
                  accept_all_btn: 'Přijmout vše',
                  reject_all_btn: 'Odmítnout vše',
                  close_btn_label: 'Zavřít',
                  cookie_table_headers: [
                    { col1: 'Název' },
                    { col2: 'Doména' },
                    { col3: 'Expirace' },
                    { col4: 'Popis' },
                  ],
                  blocks: [
                    {
                      title: 'Využití cookies 📢',
                      description:
                        'K personalizaci obsahu a reklam, poskytování funkcí sociálních médií a analýze naší návštěvnosti využíváme soubory cookie. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro sociální média, inzerci a analýzy. Partneři tyto údaje mohou zkombinovat s dalšími informacemi, které jste jim poskytli nebo které získali v důsledku toho, že používáte jejich služby.',
                    },
                    {
                      title: 'Systémové cookies',
                      description: 'Tyto soubory jsou nezbytné pro správné fungování webu',
                      toggle: {
                        value: 'necessary',
                        enabled: true,
                        readonly: true,
                      },
                      cookie_table: [
                        {
                          col1: 'cc_cookie_consent',
                          col2: 'anyrent.cz',
                          col3: '2 roky',
                          col4: 'Uchování nastavení vašich cookie preferencí',
                        },
                        {
                          col1: 'G_ENABLED_IDPS',
                          col2: 'anyrent.cz',
                          col3: '2 roky',
                          col4: 'Možnost přihlášení se k účtu prostřednictvím Single Sign on od Googlu.',
                        },
                        {
                          col1: 'i18next',
                          col2: 'anyrent.cz',
                          col3: 'Do odstranění',
                          col4: 'Soubory používané k lokalizaci uživatele.',
                        },
                      ],
                    },
                    {
                      title: 'Výkonostní a analytické cookies',
                      description:
                        'Soubory používané k zapamatování si vašeho nastavení, které jste provedli v minulosti',
                      toggle: {
                        value: 'analytics', // your cookie category
                        enabled: false,
                        readonly: false,
                      },
                      cookie_table: [
                        {
                          col1: '_gid',
                          col2: 'google.com',
                          col3: '1 den',
                          col4:
                            'Společnost Google používá tyto soubory cookies na základě posledních vyhledávání a interakcí pro interní analýzu chování zákazníků',
                        },
                        {
                          col1: '_gac',
                          col2: 'google.com',
                          col3: '3 měsíce',
                          col4: 'Společnost Google používá tyto soubory cookies ke sledování návštěvnosti webu.',
                          is_regex: true,
                        },
                        {
                          col1: 'SL_C_*_SID',
                          col2: 'smartlook.com',
                          col3: '13 měsíců',
                          col4: 'Kód pro identifikaci uživatele.',
                        },
                      ],
                    },
                    {
                      title: 'Reklamní a retargetingové cookies',
                      description: 'Soubory využívané ke sledování chování na webu a přizpůsobení inzertních systémů',
                      toggle: {
                        value: 'targeting',
                        enabled: false,
                        readonly: false,
                      },
                      cookie_table: [
                        {
                          col1: '_gcl_au',
                          col2: 'anyrent.cz',
                          col3: '3 měsíce',
                          col4:
                            'Soubory cookies jsou za pomoci Google Analytics použity k pochopení interakce uživatele a webu.',
                        },

                        {
                          col1: 'APNUID',
                          col2: 'seznam.cz',
                          col3: '30 dní',
                          col4: 'Společnost Seznam používá tyto soubory cookies pro personalizaci reklamních sdělení.',
                        },
                        {
                          col1: 'sid',
                          col2: 'seznam.cz',
                          col3: 'Do odstranění',
                          col4: 'Kód pro identifikaci uživatele.',
                        },
                        {
                          col1: 'sid',
                          col2: 'imedia.cz',
                          col3: 'Do odstranění',
                          col4: 'Kód pro identifikaci uživatele.',
                        },
                        {
                          col1: 'KADUSERCOOKIE',
                          col2: 'seznam.cz',
                          col3: '6 měsíců',
                          col4: '',
                        },
                      ],
                    },
                    {
                      title: 'Více informací',
                      description:
                        'Pro více informací nás prosím <a class="link" href="mailto:info@anyrent.cz">kontaktujte</a>.',
                    },
                  ],
                },
              },
            },
          });

          app.$gtm.trackEvent({
            event: 'cookieConsentLoaded',
            consents: {
              analytics: (cookieConsent && cookieConsent.allowedCategory('analytics')) || false,
              targeting: (cookieConsent && cookieConsent.allowedCategory('targeting')) || false,
            },
          });
        }
        app.$gtm.trackEvent({
          event: 'virtualPageview',
          pageUrl: to.path,
          pageTitle: document.title,
          routeName: to.name,
        });
      }
    });
  });
}
