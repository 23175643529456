import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import SearchbarCategories from './types/selectedOptions'

const mutations: MutationTree<SearchbarCategories> = {
  [types.SET] (state, filters) {
    // always rewrite
    state.options = filters
  }
}

export default mutations
