import config from 'config';
import { DataResolver } from './types/DataResolver';
import fetch from 'isomorphic-fetch';

const getPartners = () => {
  const host = config.elasticsearch.host;
  const index = config.elasticsearch.index;
  const url = `${host}/${index}/partner/_search`;
  return new Promise<any[]>((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        sort: { 'name.keyword': 'asc' },
        size: 10000
      })
    })
      .then(response => response.json())
      .then(data => {
        const partners = data.hits.hits.reduce((acc, hit) => {
          acc.push(hit._source);
          return acc;
        }, []);
        partners.sort((a, b) => a.name.localeCompare(b.name, 'cs', {sensitivity: 'base'}))
        resolve(partners);
      })
      .catch(err => reject(err));
  });
};

export const PartnerService: DataResolver.PartnerService = {
  getPartners
};
