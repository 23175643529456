import { nonReactiveState } from './index';
import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from './CategoryState'
import { compareByLabel } from '../../helpers/categoryHelpers'
import { products } from 'config'
import FilterVariant from '../../types/FilterVariant'
import { optionLabel } from '../../helpers/optionLabel'
import trim from 'lodash-es/trim'
import toString from 'lodash-es/toString'
import forEach from 'lodash-es/forEach'
import { getFiltersFromQuery, getFiltersFromQueryInfo } from '../../helpers/filterHelpers';
import { Category, Filters } from '../../types/Category';
import { parseCategoryPath } from '@vue-storefront/core/modules/breadcrumbs/helpers'
import { _prepareCategoryPathIds, getSearchOptionsFromRouteParams } from '../../helpers/categoryHelpers';
import { removeStoreCodeFromRoute } from '@vue-storefront/core/lib/multistore'
import cloneDeep from 'lodash-es/cloneDeep'

type FiltersQueryInfoType = { searchQuery: { filters: Filters }, nonApplicableFilters: Object, isQueryApplicable }

function mapCategoryProducts (productsFromState, productsData) {
  return productsFromState.map(prodState => {
    if (typeof prodState === 'string') {
      const product = productsData.find(prodData => prodData.sku === prodState)
      return cloneDeep(product)
    }
    return prodState
  })
}

const getters: GetterTree<CategoryState, RootState> = {
  getCategories: (state): Category[] => Object.values(state.categoriesMap),
  getCategoriesMap: (state): { [id: string]: Category} => state.categoriesMap,
  getNotFoundCategoryIds: (state): string[] => state.notFoundCategoryIds,
  getCategoryProducts: (state) => mapCategoryProducts(state.products, nonReactiveState.products),
  getCategoryFrom: (state, getters) => (path: string = '') => {
    return getters.getCategories.find(category => (removeStoreCodeFromRoute(path) as string).replace(/^(\/)/gm, '') === category.url_path)
  },
  getCategoryByParams: (state, getters, rootState) => (params: { [key: string]: string } = {}) => {
    return getters.getCategories.find(category => {
      const valueCheck = []
      const searchOptions = getSearchOptionsFromRouteParams(params)
      forEach(searchOptions, (value, key) => valueCheck.push(category[key] && category[key] === (category[key].constructor)(value)))
      return valueCheck.filter(check => check === true).length === Object.keys(searchOptions).length
    }) || {}
  },
  getCurrentCategory: (state, getters, rootState) => {
    return getters.getCategoryByParams(rootState.route.params)
  },
  getAvailableFiltersFrom: (state, getters, rootState) => (aggregations) => {
    const filters = {}
    if (aggregations) { // populate filter aggregates
      // changing the default config based product default filters with vuex boundm preloaded ones
      const dynamicAttributes = rootState.attribute.preloaded
      // for (let attrToFilter of products.defaultFilters) {
      for (const attrToFilter of dynamicAttributes) { // fill out the filter options
        const filterOptions: FilterVariant[] = []

        const uniqueFilterValues = new Set<string>();
        if (attrToFilter === 'price') {
        // special case is range filter for prices
          const storeView = rootState.storeView;
          const currencySign = storeView.i18n.currencySign;
          if (aggregations['agg_range_' + attrToFilter]) {
            let index = 0;
            const count = aggregations['agg_range_' + attrToFilter].buckets.length;
            for (const option of aggregations['agg_range_' + attrToFilter].buckets) {
              filterOptions.push({
                id: option.key,
                type: attrToFilter,
                from: option.from,
                to: option.to,
                label:
                index === 0 || index === count - 1
                  ? option.to
                    ? '< ' + currencySign + option.to
                    : '> ' + currencySign + option.from
                  : currencySign + option.from + (option.to ? ' - ' + option.to : ''), // TODO: add better way for formatting, extract currency sign
                single: true
              });
              index++;
            }
            filters[attrToFilter] = filterOptions;
          }
        } else {
          if (aggregations['agg_terms_' + attrToFilter]) {
            let buckets = aggregations['agg_terms_' + attrToFilter].buckets;
            if (aggregations['agg_terms_' + attrToFilter + '_options']) {
              buckets = buckets.concat(aggregations['agg_terms_' + attrToFilter + '_options'].buckets);
            }

            for (const option of buckets) {
              uniqueFilterValues.add(toString(option.key));
            }
          }
          if (attrToFilter === 'partner') {
            const partners = rootState['partner'].partners;
            const uniquePartners = [];
            uniqueFilterValues.forEach(key => uniquePartners.push(partners.find(p => +p.id === +key)));
            uniquePartners.forEach(partner =>
              filterOptions.push({
                id: partner.id,
                type: attrToFilter,
                label: partner.name
              })
            );
          } else {
            uniqueFilterValues.forEach(key => {
              const label = optionLabel(rootState.attribute, { attributeKey: attrToFilter, optionId: key });
              if (trim(label) !== '') {
              // is there any situation when label could be empty and we should still support it?
                filterOptions.push({
                  id: key,
                  label: label,
                  type: attrToFilter
                });
              }
            });
          }
          filters[attrToFilter] = filterOptions.sort(compareByLabel);
        }
      }
      // Add sort to available filters
      const variants = []
      Object.keys(products.sortByAttributes).map(label => {
        variants.push({
          label: label,
          id: products.sortByAttributes[label],
          type: 'sort'
        })
      })
      filters['sort'] = variants
    }
    return filters
  },
  getFiltersMap: state => state.filtersMap,
  getAvailableFilters: (state, getters) => {
    const fx = getters.getCurrentCategory ? state.filtersMap[getters.getCurrentCategory.id] : {}
    return fx
  },
  getCurrentFiltersFrom: (state, getters, rootState, rootGetters) => (filters, categoryFilters) => {
    const currentQuery = filters || rootState.route[products.routerFiltersSource]
    const availableFilters = categoryFilters || getters.getAvailableFilters
    const x = getFiltersFromQuery({availableFilters, filtersQuery: currentQuery}, rootGetters)
    return x
  },
  getFiltersFromQueryInfo: (state, getters, rootState, rootGetters) => (filters, categoryFilters)
  :FiltersQueryInfoType => {
    const currentQuery = filters || rootState.route[products.routerFiltersSource];
    const availableFilters = categoryFilters || getters.getAvailableFilters;
    return getFiltersFromQueryInfo({ availableFilters, filtersQuery: currentQuery }, rootGetters);
  },
  getCurrentSearchQuery: (state, getters, rootState) => getters.getCurrentFiltersFrom(rootState.route[products.routerFiltersSource]),
  getCurrentFilters: (state, getters) => getters.getCurrentSearchQuery.filters,
  hasActiveFilters: (state, getters) => !!Object.keys(getters.getCurrentFilters).length,
  getSystemFilterNames: () => products.systemFilterNames,
  getBreadcrumbs: (state, getters) => getters.getBreadcrumbsFor(getters.getCurrentCategory),
  getBreadcrumbsFor: (state, getters) => category => {
    if (!category) return []
    const categoryHierarchyIds = _prepareCategoryPathIds(category)
    const resultCategoryList = categoryHierarchyIds.map(categoryId => {
      return getters.getCategoriesMap[categoryId]
    }).filter(c => !!c)
    return parseCategoryPath(resultCategoryList)
  },
  getCategorySearchProductsStats: state => state.searchProductsStats || {},
  getCategoryProductsTotal: (state, getters) => {
    const { total } = getters.getCategorySearchProductsStats
    const totalValue = typeof total === 'object' ? total.value : total

    return totalValue || 0
  }
}

export default getters
