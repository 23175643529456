import { MutationTree } from 'vuex';
import * as types from './mutation-types';
import CookiesState from '../types/CookiesState';

const mutations: MutationTree<CookiesState> = {
  [types.COOKIES_SET_COOKIE_CONSENT] (state, cookieConsent: object) {
    state.cookieConsent = cookieConsent;
  }
};

export default mutations;
