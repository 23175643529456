import * as types from '@vue-storefront/core/modules/cart/store/mutation-types';
import { Logger } from '@vue-storefront/core/lib/logger';
import { CartService } from '@vue-storefront/core/data-resolver';
import {
  preparePaymentMethodsToSync,
  prepareShippingInfoForUpdateTotals,
  createOrderData,
  createShippingInfoData
} from '@vue-storefront/core/modules/cart/helpers';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus';

const totalsActions = {
  async getTotals (context, { addressInformation, hasShippingInformation }) {
    if (hasShippingInformation) {
      return CartService.setShippingInfo(addressInformation);
    }

    return CartService.getTotals();
  },
  async updateTotalsAfterAdditionalService ({ commit, getters, rootGetters, dispatch }, { services }) {
    const cartId = rootGetters['cart/getCartToken'];
    const token = rootGetters['user/getToken'];

    const payload = [];
    for (const method of services) {
      const serviceMethod = {
        item_id: method.item_id,
        sku: method.parentProductSku,
        code: method.code,
        price: method.price,
        name: method.name,
        selected_option_id: method.selected_option ? method.selected_option.id : undefined,
        id: method.id
      };
      payload.push(serviceMethod);
    }
    const { resultCode, result } = await CartService.additionalServicesPrice(payload, token, cartId);

    if (resultCode === 200) {
      const totals = result.totals || result;
      Logger.info('Overriding server totals. ', 'additional services', totals)();
      const itemsAfterTotal = prepareShippingInfoForUpdateTotals(totals.items);

      for (const key of Object.keys(itemsAfterTotal)) {
        const item = itemsAfterTotal[key];
        const product = { server_item_id: item.item_id, totals: item, qty: item.qty };
        await dispatch('updateItem', { product });
      }

      commit(types.CART_UPD_TOTALS, { itemsAfterTotal, totals, platformTotalSegments: totals.total_segments });
      commit(types.CART_SET_TOTALS_SYNC);

      // we received payment methods as a result of this call, updating state
      if (result.payment_methods && getters.canUpdateMethods) {
        const { uniqueBackendMethods, paymentMethods } = preparePaymentMethodsToSync(
          result.payment_methods.map(method => ({ ...method, is_server_method: true })),
          rootGetters['checkout/getNotServerPaymentMethods']
        );
        dispatch('checkout/replacePaymentMethods', paymentMethods, { root: true });
        EventBus.$emit('set-unique-payment-methods', uniqueBackendMethods);
      }
    }
  },
  async overrideServerTotals (
    { commit, getters, rootGetters, dispatch },
    { addressInformation, hasShippingInformation }
  ) {
    const { resultCode, result } = await dispatch('getTotals', { addressInformation, hasShippingInformation });
    /// !!!
    if (resultCode === 200) {
      const totals = result.totals || result;
      Logger.info('Overriding server totals. ', 'cart', totals)();
      const itemsAfterTotal = prepareShippingInfoForUpdateTotals(totals.items);

      for (const key of Object.keys(itemsAfterTotal)) {
        const item = itemsAfterTotal[key];
        const product = { server_item_id: item.item_id, totals: item, qty: item.qty };
        await dispatch('updateItem', { product });
      }

      commit(types.CART_UPD_TOTALS, { itemsAfterTotal, totals, platformTotalSegments: totals.total_segments });
      commit(types.CART_SET_TOTALS_SYNC);

      // we received payment methods as a result of this call, updating state
      if (result.payment_methods && getters.canUpdateMethods) {
        const { uniqueBackendMethods, paymentMethods } = preparePaymentMethodsToSync(
          result.payment_methods.map(method => ({ ...method, is_server_method: true })),
          rootGetters['checkout/getNotServerPaymentMethods']
        );
        dispatch('checkout/replacePaymentMethods', paymentMethods, { root: true });
        EventBus.$emit('set-unique-payment-methods', uniqueBackendMethods);
      }

      return;
    }

    Logger.error(result, 'cart')();
  },
  async syncTotals (
    { dispatch, getters, rootGetters },
    payload: { forceServerSync: boolean, methodsData?: any, isReturn?: Boolean } = {
      forceServerSync: false,
      methodsData: null,
      isReturn: false
    }
  ) {
    const methodsData = payload ? payload.methodsData : null;
    await dispatch('pullMethods', { forceServerSync: payload.forceServerSync });
    if (getters.canSyncTotals && (getters.isTotalsSyncRequired || payload.forceServerSync)) {
      const shippingMethodsData =
        methodsData ||
        createOrderData({
          shippingDetails: rootGetters['checkout/getShippingDetails'],
          returnShippingDetails: rootGetters['checkout/getReturnShippingDetails'], // !
          shippingMethods: rootGetters['checkout/getShippingMethods'],
          paymentMethods: rootGetters['checkout/getPaymentMethods'],
          paymentDetails: rootGetters['checkout/getPaymentDetails'],
          hasTwoDeliveryAdresses: false // payload.methodsData.isReturnDelivery TODO connect to vuex..
        });

      if (shippingMethodsData.country) {
        return dispatch('overrideServerTotals', {
          hasShippingInformation: shippingMethodsData.shippingMethodId,
          addressInformation: createShippingInfoData(shippingMethodsData)
        });
      }

      Logger.error('Please do set the tax.defaultCountry in order to calculate totals', 'cart')();
    }
  },
  async refreshTotals ({ dispatch }, payload) {
    Logger.warn(
      'The "cart/refreshTotals" action is deprecated and will not be supported with the Vue Storefront 1.11',
      'cart'
    )();
    return dispatch('syncTotals', payload);
  }
};

export default totalsActions;
