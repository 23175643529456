import { Logger } from '@vue-storefront/core/lib/logger';
import config from 'config';
import { processURLAddress } from '@vue-storefront/core/helpers';
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver';
import MailItem from '@vue-storefront/core/modules/mailer/types/MailItem';

const sendEmail = async (letter: MailItem) => {
  try {
    const res = await fetch(processURLAddress(config.mailer.endpoint.token));
    const resData = await res.json();
    if (resData.code === 200) {
      try {
        const res = await fetch(
          processURLAddress(config.mailer.endpoint.send),
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ...letter,
              token: resData.result
            })
          }
        );
        return res;
      } catch (e) {
        Logger.error(e, 'mailer')();
        throw new Error(e);
      }
    } else {
      throw new Error(resData.code);
    }
  } catch (e) {
    Logger.error(e, 'mailer')();
    throw new Error(e);
  }
};
export const MailService: DataResolver.MailService = {
  sendEmail
};
