import { ActionTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import EquipmentGuideState from '../types/EquipmentGuideState';
import { EQUIPMENT_GUIDE_SET_EQUIPMENT_GUIDE } from './mutation-types';

const actions: ActionTree<EquipmentGuideState, RootState> = {
  async setEquipmentGuide ({ commit }, equipmentGuide) {
    commit(EQUIPMENT_GUIDE_SET_EQUIPMENT_GUIDE, equipmentGuide);
  }
};

export default actions;
