// import { Logger } from '@vue-storefront/core/lib/logger'

const createShippingInfoData = (methodsData) => {
  // Logger.info('methodsData', 'S H P  === ',methodsData)()

  const info = {
    shippingAddress: {
      countryId: methodsData.country,
      ...(methodsData.billingAddress || {})
    },
    billingAddress: {
      ...(methodsData.billingAddress ? methodsData.billingAddress : {})
    },
    returnShippingAddress: {
      ...(methodsData.returnShippingAddress ? methodsData.returnShippingAddress : {})
    },
    shippingCarrierCode: methodsData.carrier_code,
    shippingMethodId: methodsData.shippingMethodId,
    returnShippingMethodId: methodsData.returnShippingMethodId
  };
  if (methodsData.returnShippingDetails) {
    info.returnShippingMethodId = methodsData.returnShippingDetails.shippingMethod
  }
  return info
}
export default createShippingInfoData
