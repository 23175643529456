import { DataResolver } from './types/DataResolver'
import Task from '@vue-storefront/core/lib/sync/types/Task'
import CartItem from '@vue-storefront/core/modules/cart/types/CartItem'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { processLocalizedURLAddress } from '@vue-storefront/core/helpers'
import config from 'config';

const setShippingInfo = async (addressInformation: any): Promise<Task> => {
  return TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.shippinginfo_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ addressInformation })
    },
    silent: true
  });
}

const getTotals = async (): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.totals_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });

const getCartToken = async (guestCart: boolean = false, forceClientState: boolean = false): Promise<Task> => {
  const url = processLocalizedURLAddress(guestCart
    ? config.cart.create_endpoint.replace('{{token}}', '')
    : config.cart.create_endpoint)

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    force_client_state: forceClientState,
    silent: true
  });
}

const updateItem = async (cartServerToken: string, cartItem: CartItem): Promise<Task> => {
  return TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.updateitem_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        cartItem: {
          ...cartItem,
          quoteId: cartItem.quoteId || cartServerToken
        }
      })
    }
  });
}

const deleteItem = async (cartServerToken: string, cartItem: CartItem): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.deleteitem_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        cartItem: {
          ...cartItem,
          quoteId: cartServerToken
        }
      })
    },
    silent: true
  });

const getPaymentMethods = async (): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.paymentmethods_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });

const getShippingMethods = async (address: any): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.shippingmethods_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        address
      })
    },
    silent: true
  });

const getItems = async (): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.pull_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });

const applyCoupon = async (couponCode: string): Promise<Task> => {
  const url = processLocalizedURLAddress(config.cart.applycoupon_endpoint.replace('{{coupon}}', couponCode))

  return TaskQueue.execute({
    url,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: false
  });
}

const removeCoupon = async (): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.deletecoupon_endpoint),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: false
  });

const getCoupon = async (): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.getcoupon_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: false
  });

const additionalServices = async (): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.pull_endpoint),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  });

const intervalPrice = async (sku: string, quantity: number, dateFrom: string, dateTo: string, token: string): Promise<Task> => {
  if (!dateTo || !dateFrom) {
    return
  }
  return TaskQueue.execute({
    url: processLocalizedURLAddress(`${(config.cart.interval_price_endpoint)}from=${encodeURIComponent(dateFrom)}&to=${encodeURIComponent(dateTo)}&token=${token}&sku=${sku}&qty=${quantity}`),
    payload: {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    },
    silent: true
  })
};

const additionalServicesPrice = async (services: any, token: string, cartId:string): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(config.cart.additional_services_endpoint.replace('{{token}}', token).replace('{{cartId}}', cartId)),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({ additionalServices: services })
    },
    silent: true
  });

const startPayment = async (userToken: string, cartId:string): Promise<Task> =>
  TaskQueue.execute({
    url: processLocalizedURLAddress(`${(config.cart.start_payment_endpoint)}`),
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        userToken: userToken,
        cartId: cartId
      })
    },
    silent: true
  });

export const CartService: DataResolver.CartService = {
  setShippingInfo,
  getTotals,
  getCartToken,
  updateItem,
  deleteItem,
  getPaymentMethods,
  getShippingMethods,
  getItems,
  applyCoupon,
  removeCoupon,
  getCoupon,
  additionalServices,
  additionalServicesPrice,
  intervalPrice,
  startPayment
}
