import * as types from './mutation-types';
import { CartService } from '@vue-storefront/core/data-resolver';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

const actions = {
  // not used
  async fetchServices (context) {
    const { result } = await CartService.additionalServices();
    return 'test action';
  },
  // not used
  async pushAdditionalServices ({ rootState, commit, getters, rootGetters, dispatch }, { services }) {
    // todo
  },
  // this prepares data for checkboxes, so they appear
  // as active when user has selected them previously
  // and reopens the checkout again
  async syncActive ({ rootState, commit, getters, rootGetters, dispatch }, syncedProducts) {
    const services = []
    const productsInCart = rootGetters['cart/getCartItems']

    for (const product of syncedProducts) {
      if (product.selectedAdditionalServices) {
        try {
          // Using Array.from to create a non-reactive shallow copy of `selectedAdditionalServices`.
          // Vue's reactivity system is wrapping arrays with observers, which is interfering with iteration.
          // This ensures we can safely iterate over it as a regular JavaScript array.
          // The `as typeof product.selectedAdditionalServices` is keeping the type consistent.
          const additionalServices = Array.from(product.selectedAdditionalServices) as typeof product.selectedAdditionalServices;
          for (const serviceMethod of additionalServices) {
            const match = productsInCart.find((productInCart) => {
              return productInCart.sku === product.sku;
            });
            const itemService = {
              code: serviceMethod.code,
              name: serviceMethod.name,
              id: serviceMethod.id,
              price: serviceMethod.price,
              serviceKey: `${serviceMethod.item_id}_${serviceMethod.id}`,
              item_id: serviceMethod.item_id,
              parentProduct: match?.name,
              parentProductSku: product.sku,
              isActive: true,
              selected_option: serviceMethod.selected_option
            };
            services.push(itemService);
          }
        } catch (err) {
          console.error('🚨 Error during iteration of selectedAdditionalServices:', {
            error: err,
            selectedAdditionalServices: product.selectedAdditionalServices,
            product
          });
        }
      }
    }
    commit(types.SET_ACTIVE, services)
  },
  // this is being called anytime user changes
  // the services
  setActive ({ rootState, commit, getters, rootGetters, dispatch }, services) {
    // propagate the changes to server
    EventBus.$emit('checkout-after-additionalServicesChanged', services)
    commit(types.SET_ACTIVE, services)
  },
  setBiometricData ({ commit }, biometricData) {
    commit(types.SET_BIOMETRIC_DATA, biometricData); // Action to commit biometric data to the state
  }
};

export default actions;
