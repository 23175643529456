import * as types from './mutation-types';
// import { CartService } from '@vue-storefront/core/data-resolver';
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger' // for debug

const actions = {
  // get categories from ES and store them.
  async fetchCategories ({state, commit}) {
    // check if there are categories already, if so, skip reload
    if (state.categories.length > 1) {
      Logger.debug('state', 'not loading cats', state.categories)()
      return 'done' // to fullfill the promise
    } else {
      Logger.debug('state', 'loading cats', state.categories)()
    }

    // create ES query
    let searchQuery = new SearchQuery()
    // only active
    searchQuery = searchQuery.applyFilter({key: 'is_active', value: { 'eq': true }})
    // only with kids
    searchQuery = searchQuery.applyFilter({key: 'product_count', value: { 'gt': 0 }})

    const sort = 'position:asc'
    const includeFields = config.entities.optimize ? config.entities.category.includeFields : null
    const excludeFields = config.entities.optimize ? config.entities.category.excludeFields : null
    const size = 150
    const start = 0

    // include position so we can see the order of the dropdown
    includeFields.push('position')

    const resp = await quickSearchByQuery({ entityType: 'category', query: searchQuery, sort, size, start, includeFields, excludeFields })
    const categories = resp.items
    const categoriesSorted = []

    // recursive sort objs in arr by their position parameter
    const sortArray = (arr) => {
      if (arr.length <= 0) {
        return []
      }
      const sortedArray = [];
      // find the smallest one
      const findSmallest = arr => {
        // let smallest = 0; // default
        for (const a of arr) {
          let isTheSmallest = true;
          for (const b of arr) {
            if (a.position > b.position) {
              isTheSmallest = false;
            }
          }
          if (isTheSmallest) {
            return a;
          }
        }
        return arr;
      };

      let counter = 0; // safety 4 memory
      const reOrder = (arr) => {
        const newArr = arr.slice() // copy the array
        counter++;
        // find the smallest one
        const smallest = findSmallest(newArr);
        // add it to the result
        sortedArray.push(smallest);
        const b = newArr.findIndex(ele => ele.id === smallest.id);
        newArr.splice(b, 1);

        if (counter > 1000 || newArr.length <= 0) {
          return;
        }
        reOrder(newArr);
      };
      reOrder(arr);
      return sortedArray;
    };
    // format for css etc
    const addClass = (ele, index) => ({
      id: ele.id,
      classLevel: index,
      parentId: ele.parent_id,
      name: ele.name,
      slug: ele.slug,
      position: ele.position,
      level: ele.level
    })

    // create the root categories where root is hardcoded to 2
    // sort them
    const cats = categories.filter(category => category.level === 2)
    const rootCats = sortArray(cats)
    for (const item of rootCats) {
      categoriesSorted.push(addClass(item, 1))
    }

    // add recursively subcategories
    const addSubCats = (level) => {
      // create level cats
      const levelCategories = categoriesSorted.filter(category => category.level === level)
      if (levelCategories.length <= 0) {
        return
      }
      for (const item of levelCategories) {
        const arr = categories.filter(category => category.parent_id === item.id)
        const sortedCats = sortArray(arr)

        // find the parent's position
        const b = categoriesSorted.findIndex(category => category.id === item.id)

        // define arr position
        let pos = b + 1
        for (const ele of sortedCats) {
          // insert
          categoriesSorted.splice(pos, 0, addClass(ele, level))
          pos++
        }
      }

      level++
      // hardstop preventing memory leak
      if (level > 100) return // stop
      addSubCats(level)
    }
    addSubCats(2) // initial level for resursion

    commit(types.FETCH, categoriesSorted)
    return 'loading categories done' // to fullfill the promise
  }
};

export default actions;
