import { isServer } from '@vue-storefront/core/helpers';
import Vue from 'vue';
import VueGtm from 'vue-gtm';

export function afterOrderPlaced (config) {
  if (!isServer && config.googleTagManager.id && config.googleTagManager.conversionLabel) {
    const GTM: VueGtm = (Vue as any).gtm;
    Vue.prototype.$bus.$on('order-after-placed', event => {
      GTM.trackEvent({
        event: 'purchase',
        transactionId: event.order.order_id,
        currency: 'CZK',
        transactionTotal: event.totalPrice
      })
    });
  }
}
