import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import ReservationsState from '../../types/ReservationsState'

export const reservationsModule: Module<ReservationsState, RootState> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state: {
    dateFrom: '',
    dateTo: ''
  }
}
