// simple check of validity of products

export const checkProducts = (products) => {
  for (const product of products) {
    product.errMessage = [];

    const hasConfigurableOptions = configurableOptionsCheck(product)
    if (!hasConfigurableOptions) {
      product.isValid = false
      product.errMessage.push('configurable_options error')
    }

    const hasEmptyPriceList = emptyPriceList(product)
    if (hasEmptyPriceList) {
      product.isValid = false
      product.errMessage.push('empty price list')
    }
  }
  return products
}

function emptyPriceList (product) {
  for (const child of product.configurable_children) {
    if (child.price_list && child.price_list.length === 0) {
      console.error('%c ERROR > empty price_list in (sku): %c' + product.sku + ' / ' + product.name, 'color: red', 'color: grey')
      return true
    // } else if (!child.price_list) {
    //   console.log('%c ERROR >  %c' +product.sku + ' / ' +product.name, "color: red","color: grey")
    //   return true
    }
  }
  return false
}

function configurableOptionsCheck (product) {
  if (!product.configurable_options || product.configurable_options.length <= 0) {
    console.error('%c ERROR > in configurable_options in (sku): %c' + product.sku + ' / ' + product.name, 'color: red', 'color: grey')
    return false
  }
  return true
}
