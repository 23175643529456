import { server, graphql } from 'config'
import Vue from 'vue'
import { Logger } from '@vue-storefront/core/lib/logger'
import { once } from '@vue-storefront/core/helpers'
import config from 'config'
import introspectionQueryResultData from '@vue-storefront/core/fragmentTypes.json'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { isServer } from '@vue-storefront/core/helpers';

export const getApolloProvider = async () => {
  const ApolloModule = await import(/* webpackChunkName: "vsf-graphql" */ 'vue-apollo')
  const VueApollo = ApolloModule.default

  once('__VUE_EXTEND_GQL__', () => {
    Vue.use(VueApollo)
  })

  const HttpLinkModule = await import(/* webpackChunkName: "vsf-graphql" */ 'apollo-link-http')
  const HttpLink = HttpLinkModule.HttpLink

  const httpLink = new HttpLink({
    uri: config.strapi.url + '/graphql'
  })

  const ApolloClientModule = await import(/* webpackChunkName: "vsf-graphql" */ 'apollo-client')
  const ApolloClient = ApolloClientModule.default
  const inMemoryCacheModule = await import(/* webpackChunkName: "vsf-graphql" */ 'apollo-cache-inmemory')
  const InMemoryCache = inMemoryCacheModule.InMemoryCache

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  const cache = new InMemoryCache({ fragmentMatcher });

  // If on the client, recover the injected state
  if (!isServer) {
    const state = window.__APOLLO_STATE__;
    if (state) {
      // If you have multiple clients, use `state.<client_id>`
      cache.restore(state.defaultClient);
    }
  }

  const apolloClient = new ApolloClient({
    link: httpLink,
    cache,
    ...(isServer ? {
      // Set this on the server to optimize queries when SSR
      ssrMode: true
    } : {
      // This will temporary disable query force-fetching
      ssrForceFetchDelay: 100
    }),
    connectToDevTools: true
  })

  let loading = 0

  const apolloProvider = new VueApollo({
    clients: {
      a: apolloClient
    },
    defaultClient: apolloClient,
    defaultOptions: {
      // $loadingKey: 'loading',
    },
    watchLoading (state, mod) {
      loading += mod
      Logger.log('Global loading', loading, mod)()
    },
    errorHandler (error) {
      Logger.log('Global error handler')()
      Logger.error(error)()
    }
  })

  return apolloProvider
}

export default {
  getApolloProvider
}
