import CartItem from '@vue-storefront/core/modules/cart/types/CartItem';
import rootStore from '@vue-storefront/core/store'

const addDates = (cartItem: CartItem): CartItem => {
  const reservationDates = rootStore.getters['reservations/getCurrentDates']
  const newCartItem = cartItem;
  if (reservationDates.from === '') {
    reservationDates.from = '1975-08-01';
    reservationDates.to = '1980-08-01';
  }
  // the position in arrays will not change
  newCartItem.dateFrom = reservationDates.from;
  newCartItem.dateTo = reservationDates.to;

  return newCartItem
};

export default addDates;
