import rootStore from '@vue-storefront/core/store'

const addDates = ():any => {
  const reservationDates = rootStore.getters['reservations/getCurrentDates']
  // TODO better error handling
  if (reservationDates.from === '') {
    reservationDates.from = '1975-08-01';
    reservationDates.to = '1980-08-01';
  }
  // change the format of the dates
  const dates = {
    dateFrom: reservationDates.from,
    dateTo: reservationDates.to
  }
  return dates
}

export default addDates
