import { ActionTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';
import CategoryState from './PartnerState';
import { PartnerService } from '@vue-storefront/core/data-resolver';
import { Logger } from '@vue-storefront/core/lib/logger';
import { PARTNERS_SET_PARTNERS } from './mutation-types';

const actions: ActionTree<CategoryState, RootState> = {
  async setPartners ({ commit }) {
    let partners;
    try {
      partners = await PartnerService.getPartners();
      commit(PARTNERS_SET_PARTNERS, partners);
    } catch (e) {
      Logger.error('partners request failed', e);
      commit(PARTNERS_SET_PARTNERS, []);
    }
  }
};

export default actions;
