import { historyStore } from './store';
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus/index';
import { StorageManager } from '@vue-storefront/core/lib/storage-manager';
import { isServer } from '@vue-storefront/core/helpers';
import { StorefrontModule } from '@vue-storefront/core/lib/modules';

export const HistoryModule: StorefrontModule = function ({ store }) {
  StorageManager.init('history');

  if (!isServer) {
    EventBus.$on('route-changed', async event => {
      await store.dispatch('history/setRoutes', { to: event.to, from: event.from });
    });
  }
  store.registerModule('history', historyStore);
};
