import { entities } from 'config'
import { Category, ChildrenData } from '../types/Category'

export const compareByLabel = (a, b) => {
  return a.label.localeCompare(b.label, 'cs', {sensitivity: 'base'})
}

export const _prepareCategoryPathIds = (category: Category): string[] => {
  if (!category || !category.path) return []
  return category.path.split('/')
}

export const getSearchOptionsFromRouteParams = (params: { [key: string]: string } = {}): Record<string, string> => {
  const filterableKeys = entities.category.validSearchOptionsFromRouteParams
  const filters: { [key: string]: string } = {}
  Object.keys(params)
    .filter(key => filterableKeys.includes(key))
    .forEach(key => { filters[key] = params[key] })

  return filters
}
