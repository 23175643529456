import { GetterTree } from 'vuex';
import RootState from '@vue-storefront/core/types/RootState';

import { IPartner } from 'core/data-resolver/types/IPartner';
import PartnerState from 'core/modules/catalog-next/store/partner/PartnerState';

const getters: GetterTree<PartnerState, RootState> = {
  getPartners: (state): IPartner[] => state.partners,
  getPickupPoints: (state) => state.partners.map(partner =>
    partner.available_shipping_methods
      .filter(sm => sm.is_pickup_point)
      .map(sm => ({
        description: sm.description,
        partnerName: partner.name,
        partnerId: partner.id,
        dropOffDescription: sm.dropoff_description,
        ...sm.pickup_point_info
      }))).reduce((partner, acc) => [...acc, ...partner], []).sort((a, b) => a.partnerName.localeCompare(b.partnerName, 'cs', {sensitivity: 'base'}))
};

export default getters;
