import { Module } from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import EquipmentGuideState from '../types/EquipmentGuideState';

export const equipmentGuideStore: Module<EquipmentGuideState, any> = {
  namespaced: true,
  state: {
    equipmentGuide: null
  },
  actions,
  mutations,
  getters
};
