import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ReservationsState from '../../types/ReservationsState'
import * as types from './mutation-types'
import { parseTimezoneDateTo, parseTimezoneDateFrom } from '../../helpers/parseDateTimezone';

const actions: ActionTree<ReservationsState, RootState> = {

  setDateFrom ({commit}, dateFrom) {
    const result = parseTimezoneDateFrom(dateFrom);
    commit(types.SET_DATE_FROM, result)
  },
  setDateTo ({commit}, dateTo) {
    const result = parseTimezoneDateTo(dateTo);
    commit(types.SET_DATE_TO, result)
  },
  // set initial date from sync
  // there is always an item in cart if this gets called
  syncDates (context, dates) {
    if (context.state.dateFrom === '' && context.state.dateTo === '') {
      const modifiedDateFrom = parseTimezoneDateFrom(dates.dateFrom);
      const modifiedDateTo = parseTimezoneDateTo(dates.dateTo);
      context.commit(types.SET_DATE_FROM, modifiedDateFrom);
      context.commit(types.SET_DATE_TO, modifiedDateTo);
    } else {
      // console.log('reservations sync Error')
    }
  }
}

export default actions
