import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'

const productActions = {
  async findProductOption ({ dispatch }, { serverItem }) {
    if (serverItem.product_type === 'configurable') {
      return {
        sku: serverItem.parent_sku,
        childSku: serverItem.sku
      }
    }

    return { sku: serverItem.parent_sku }
  },
  async getProductVariant ({ dispatch }, { serverItem }) {
    try {
      const options = await dispatch('findProductOption', { serverItem })
      const singleProduct = await dispatch('product/single', { options, assignDefaultVariant: true, setCurrentProduct: false, selectDefaultVariant: false }, { root: true })

      return {
        ...singleProduct,
        server_item_id: serverItem.item_id,
        qty: serverItem.qty,
        server_cart_id: serverItem.quote_id,
        product_option: serverItem.product_option || singleProduct.product_option
      }
    } catch (e) {
      return null
    }
  }
}

export default productActions
