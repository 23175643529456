import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import AdditionalServices from './types/additionalServices'

export const module: Module<AdditionalServices, any> = {
  namespaced: true,
  state: {
    services: [
      {type: 'test', price: 500}
    ],
    activeServices: [],
    lastSyncedServices: [],
    biometricData: [] // Add this new state to store biometric data
  },
  getters,
  actions,
  mutations
}
