import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import Vue from 'vue';
import { getSearchOptionsFromRouteParams } from '@vue-storefront/core/modules/catalog-next/helpers/categoryHelpers';
import { PARTNER_TYPE } from '../../constants/FilterVariantTypes';

export const EventHandlers: StorefrontModule = function () {
  // Add filter when product is added to cart
  Vue.prototype.$bus.$on('application-after-init', app => {
    app.$router.beforeEach((to, from, next) => {
      app.$store.dispatch('history/setRoute', {to, from})
      next()
    })

    Vue.prototype.$bus.$on('cart-after-add', async () => {
      const partnerFilter = getPartnerFilterFromCartItems(app);
      if (partnerFilter) {
        await app.$store.dispatch('cart/setSource', { id: partnerFilter.id, name: partnerFilter.label });
      }
    });
    Vue.prototype.$bus.$on('cart-item-deleted', async () => {
      await resetSource(app)
    });
    Vue.prototype.$bus.$on('order-after-placed', async () => {
      await resetSource(app)
    });
    // redirect user to home page when accessing product with from differnet partner than that in cart
    Vue.prototype.$bus.$on('product-page-before-mount', product => {
      const partnerFilter = getPartnerFilterSource(app);
      if (partnerFilter && product.rental_info) {
        if (partnerFilter.id !== product.rental_info.source_id) {
          app.$router.push('/');
        }
      }
    });
  });

  const resetSource = async (app) => {
    const items = app.$store.getters['cart/getCartItems'];
    if (!items.length) {
      await app.$store.dispatch('cart/setSource', null);
      const query = await app.$route.query
      delete query[PARTNER_TYPE]
      await app.$router.replace({path: app.$route.path, query}).catch(err => {})
      await updateProducts(app)
    }
  }

  const getPartnerFilterSource = (app) => {
    const partnerSource = app.$store.getters['cart/getSource'];
    if (partnerSource) {
      return { id: partnerSource.id, type: PARTNER_TYPE, label: partnerSource.name };
    }
    return null;
  };
  const getPartnerFilterFromCartItems = (app) => {
    const cartItems = app.$store.getters['cart/getCartItems'];
    const item = cartItems.find(item => item.rental_info);
    if (item) {
      const partner = item.rental_info;
      return { id: partner.source_id, type: PARTNER_TYPE, label: partner.name };
    }
    return null;
  };
  const updateProducts = async app => {
    const store = app.$store;
    const route = app.$route;
    try {
      const filters = getSearchOptionsFromRouteParams(app.$route.params);
      const currentCategory = await store.dispatch('category-next/loadCategory', { filters });
      await store.dispatch('category-next/loadCategoryProducts', {
        route,
        category: currentCategory,
        pageSize: 50
      });
    } catch (e) {
      console.error('Problem with setting Category initial data!', e);
    }
  };
};
