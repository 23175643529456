import * as types from './mutation-types'
import { MutationTree } from 'vuex'
import SearchbarCategories from './types/searchbarCategories'

const mutations: MutationTree<SearchbarCategories> = {
  [types.FETCH] (state, val) {
    state.categories = val
  }
}

export default mutations
